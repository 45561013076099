import React, { useEffect, useState } from 'react';
import { Avatar, Button, Modal, Progress, Skeleton, Spin, Typography } from 'antd';
import classNames from 'classnames';
import { GiCheckMark } from 'react-icons/gi';
import { IoIosCheckmarkCircle, IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { TiStarFullOutline } from 'react-icons/ti';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';

import { ExclamationCircleFilled } from '@ant-design/icons';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { setOpenPayModal } from '../../../../redux/thread/threadSlice';

const { Title } = Typography;

const LimitModal = ({ open, onClose, thread }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);
  const isPayed = useSelector(
    (state) =>
      state.auth.user.activeSubscription &&
      (state.auth.user.activeSubscription.status == 'active' ||
        state.auth.user.activeSubscription.status == 'trialing'),
  );

  // const [selectedTab, setSelectedTab] = useState("");
  const tabs = ['step1', 'step2', 'step3'];
  const [step, setStep] = useState(0);
  const [planType, setPlanType] = useState('annual');

  const setOpenModal = () => {
    if (
      user.activeSubscription &&
      user.activeSubscription.status != 'active' &&
      user.activeSubscription.status != 'trialing'
    ) {
      Modal.confirm({
        title: 'Your last invoice payment was failed!',
        icon: <ExclamationCircleFilled />,
        content: 'Will you try to pay the invoice again?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          window.open(user.activeSubscription.hosted_invoice, '_blank');
          return new Promise((resolve, reject) => {
            setTimeout(resolve, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          console.log('Cancel');
          dispatch(setOpenPayModal('/playground'));
        },
      });
    } else {
      // setOpenModal();
      dispatch(setOpenPayModal('/playground'));
    }
  };

  /* useEffect(() => {
    if (thread && thread.analysis) {
      setSelectedTab(Object.keys(thread.analysis)[0]);
    }
  }, [thread]); */

  // useEffect(() => {
  //   if (open) {
  //     if (thread?.analysis?.[tabs[0]]) {
  //       setStep(0);
  //     } else {
  //       setStep(5);
  //     }
  //   }
  // }, [open, thread]);
  useEffect(() => {
    if (open) {
      setStep(0);
      // setStep(5);
      // setTimeout(() => {
      //   }, 1000);
    }
  }, [open]);

  // return <Modal centered classNames={{ wrapper: classNames(!siderCollapsed && "!ms-72"), content: "", mask: classNames(!siderCollapsed && "!ms-72") }} open={open} onOk={() => { }} onCancel={() => { }} footer={[]} closable={false} maskClosable={false} width={600}>
  //   <div className='text-center'>
  //     <Progress /* strokeLinecap="butt" */ percent={40} showInfo={false} size={["100%", 12]} strokeColor="#000" trailColor="#eee" />
  //     <div className="mt-4">
  //       <Title level={3} className='!font-extrabold'>You've Reached Your<br />Daily Free Limit</Title>
  //       <div className="max-w-md mx-auto my-4">
  //         <div className="flex gap-2 my-2 text-left">
  //           <GiCheckMark /> <span>Abby Pro is smarter, with over 5,000 therapy books in her Al knowledge-base.</span>
  //         </div>
  //         <div className="flex gap-2 my-2 text-left">
  //           <GiCheckMark /> <span>Enjoy unlimited text & voice messages.</span>
  //         </div>
  //         <div className="flex gap-2 my-2 text-left">
  //           <GiCheckMark /> <span>Build your history with Abby to get more personalized responses.</span>
  //         </div>
  //         <div className="flex gap-2 my-2 text-left">
  //           <GiCheckMark /> <span>Get Advice, Analysis, Personality Reports, Related Content & More!</span>
  //         </div>
  //       </div>
  //       <div className="mt-4 text-center">
  //         <Button className={classNames('px-12 font-bold')} size='large' type='primary' onClick={() => {
  //           setOpenModal();
  //         }}>{user.pm_last_four ? 'Upgrade to PRO' : '7 Day Free Trial'}</Button>
  //         <p className='mt-2 italic'>No payment due now.</p>
  //       </div>
  //     </div>
  //   </div>
  // </Modal>

  return (
    <Modal
      centered
      classNames={{
        content: classNames('!bg-white md:mb-16' /* step == 3 && "!p-0" */),
        mask: classNames(!siderCollapsed && '!ms-72'),
        wrapper: classNames(!siderCollapsed && '!ms-72'),
      }}
      width={650}
      open={open}
      onOk={() => {}}
      onCancel={() => {
        onClose();
      }}
      footer={[]}
      closable={false}
      maskClosable={true}
    >
      {thread?.analysis?.[tabs[0]] ? (
        <div className="text-center text-black">
          {step < 3 && (
            <>
              <Progress
                /* strokeLinecap="butt" */ status="active"
                percent={(step + 1) * 33.3}
                showInfo={false}
                size={['100%', 12]}
                strokeColor="#000"
                trailColor="#eee"
              />
              <div className="mt-4 text-center">
                {/* <img src={`/imgs/step${step}.png`} alt="step1" className='w-20 h-20 rounded-full' /> */}
                <div className="inline-flex items-center justify-center w-20 h-20 text-white bg-black rounded-full">
                  <div>
                    <h2 className="m-0 font-bold leading-tight">{10 + step * 5}</h2>
                    <h5 className="m-0 font-bold leading-tight">Mins</h5>
                  </div>
                </div>
              </div>
            </>
          )}
          {step < 3 && (
            <>
              {thread?.analysis?.[tabs[0]] ? (
                <PerfectScrollbar className="max-h-[calc(100vh_-_400px)] mt-4">
                  <div className={classNames('w-full text-left', step == 3 && 'bg-gray-100 p-4 rounded-lg')}>
                    {/* {step == 4 && <h2 className='mt-4 mb-2 font-extrabold text-center'>Your 7 Day Program</h2>} */}
                    {thread?.analysis && (
                      <MarkdownPreview
                        className={classNames(
                          '!bg-transparent markdown-preview analysis [&_a]:hidden [&_h2]:text-center px-4 pb-4 rounded-lg',
                        )}
                        source={thread?.analysis[tabs[step]]}
                      />
                    )}
                  </div>
                </PerfectScrollbar>
              ) : (
                <div className="text-center">
                  <Spin /> <p className="animate-pulse">Analyzing...</p>
                </div>
              )}
            </>
          )}
          {step == 3 && (
            <div className="text-center">
              <Progress
                /* strokeLinecap="butt" */ percent={99}
                showInfo={false}
                size={['100%', 12]}
                strokeColor="#000"
                trailColor="#eee"
              />
              <div className="mt-4">
                <Title level={3} className="!font-extrabold">
                  You've Reached Your
                  <br />
                  Daily Free Limit
                </Title>
                <div className="max-w-md mx-auto my-4">
                  <div className="flex gap-2 my-2 text-left">
                    <GiCheckMark />{' '}
                    <span>Abby Pro is smarter, with over 5,000 therapy books in her Al knowledge-base.</span>
                  </div>
                  <div className="flex gap-2 my-2 text-left">
                    <GiCheckMark /> <span>Enjoy unlimited text & voice messages.</span>
                  </div>
                  <div className="flex gap-2 my-2 text-left">
                    <GiCheckMark /> <span>Build your history with Abby to get more personalized responses.</span>
                  </div>
                  <div className="flex gap-2 my-2 text-left">
                    <GiCheckMark /> <span>Get Advice, Analysis, Personality Reports, Related Content & More!</span>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <Button
                    className={classNames('px-12 font-bold')}
                    size="large"
                    type="primary"
                    onClick={() => {
                      setOpenModal();
                    }}
                  >
                    {user.pm_last_four ? 'Upgrade to PRO' : '7 Day Free Trial'}
                  </Button>
                  <p className="mt-2 italic">No payment due now.</p>
                </div>
              </div>
            </div>
          )}
          <div className="">
            {step < 3 && (
              <>
                <Button
                  type="primary"
                  disabled={!thread?.analysis?.[tabs[0]]}
                  className="px-10 font-bold rounded-sm"
                  size="large"
                  onClick={() => {
                    if (isPayed && step == 2) {
                      onClose();
                    } else {
                      setStep(step + 1);
                    }
                  }}
                >
                  {isPayed && step == 2 ? 'Close' : 'Next'}
                </Button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="px-10 mb-10 text-center text-black">
          <Progress
            /* strokeLinecap="butt" */ status="active"
            percent={10}
            showInfo={false}
            size={['100%', 12]}
            strokeColor="#000"
            trailColor="#eee"
          />
          <div className="my-4 text-center">
            <Spin size="large" />
          </div>
          <h2>
            Creating Your Discussion
            <br />
            Roadmap...
          </h2>
        </div>
      )}
    </Modal>
  );
};

export default LimitModal;

// {
//   step == 3 && <div className='flex flex-wrap items-stretch justify-between'>
//     <div className="bg-gray-100 w-full md:w-2/5">
//       <div className='flex items-center justify-center p-4 h-full'>
//         <div className=''>
//           <div className="flex items-center gap-2 mb-4">
//             <img src="/imgs/emily.png" alt="emily" className='rounded-full shadow w-14 h-14' />
//             <div>
//               <h3 className='m-0'>Emily Clark</h3>
//               <div>
//                 <TiStarFullOutline className='text-lg text-yellow-600' />
//                 <TiStarFullOutline className='text-lg text-yellow-600' />
//                 <TiStarFullOutline className='text-lg text-yellow-600' />
//                 <TiStarFullOutline className='text-lg text-yellow-600' />
//                 <TiStarFullOutline className='text-lg text-yellow-600' />
//               </div>
//             </div>
//           </div>
//           <p className='m-0'>
//             "I've been using Abby for a few months now, and I can honestly say it's been a game-changer. There have been countless nights where I felt completely alone and overwhelmed, but Abby was there to guide me through the storm."
//           </p>
//         </div>
//       </div>
//     </div>
//     <div className='w-full md:w-3/5 md:pl-8 p-4'>
//       <h2 className="text-center m-0 font-bold">
//         Daily Free Limit Reached
//       </h2>
//       <p className='mt-0 text-center text-gray-400 italic text-xs'>
//         Chat without limits by upgrading to an unlimited plan.
//       </p>
//       <ul className='px-2 text-left list-none'>
//         <li className='flex gap-2 italic mt-2'><IoIosCheckmarkCircle className='text-lg text-green-700' /> Unlimited Messages</li>
//         <li className='flex gap-2 italic mt-2'><IoIosCheckmarkCircle className='text-lg text-green-700' /> Unlimited new chats</li>
//         <li className='flex gap-2 italic mt-2'><IoIosCheckmarkCircle className='text-lg text-green-700' /> Abby gets to know you & gives tailored support</li>
//       </ul>
//       {/* <div className={classNames('flex items-center justify-between p-4 border-solid cursor-pointer rounded-lg', planType == 'annual' ? 'border-2 border-black' : 'border border-gray-300')} onClick={() => setPlanType("annual")}>
//               <div>
//                 <span className='font-bold'>Annual</span> <span className='rounded-lg bg-green-200 text-green-700 text-[10px] px-1'>Save 60%</span>
//               </div>
//               <div>
//                 <span className='font-bold text-gray-600'>$12</span>/<span>yr</span>
//               </div>
//             </div>
//             <div className={classNames('flex items-center justify-between p-4 border-solid cursor-pointer rounded-lg my-4', planType == 'monthly' ? 'border-2 border-black' : 'border border-gray-300')} onClick={() => setPlanType("monthly")}>
//               <div>
//                 <span className='font-bold'>Monthly</span>
//               </div>
//               <div>
//                 <span className='font-bold text-gray-600'>$30</span>/<span>mo</span>
//               </div>
//             </div>
//             <Button block type="primary" size="large" onClick={() => {
//               if(planType == 'monthly') {
//                 window.open(`https://buy.stripe.com/test_fZeeYAfM1g7qaL6dQS?prefilled_email=${user.email}&client_reference_id=${user._id}`, '_self');
//               } else {
//                 window.open(`https://buy.stripe.com/test_14k3fSbvLcVe9H2cMN?prefilled_email=${user.email}&client_reference_id=${user._id}`, '_self');
//               }
//             }}>Upgrade Now</Button> */}
//       <Button type="primary" className='px-8 font-bold rounded-sm' size='large' onClick={() => {
//         // setShowExpiredModal(false);
//         setOpenModal();
//       }}>{user.pm_last_four ? 'Pro ($9.99/Month)' : '7 Day Free Trial'}</Button>
//       <p className='flex items-center justify-center gap-1 mt-2 italic text-gray-600'><IoIosCheckmarkCircleOutline className='text-lg' /> {user.pm_last_four ? 'Cancel Anytime' : 'No payment due now'}.</p>
//       <div className="flex gap-1 mt-4 items-center">
//         <Avatar.Group size={23}>
//           <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
//           <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
//           <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
//         </Avatar.Group>
//         <span className="text-gray-400 text-xs text-left">Join 37,102 members finding clarity & understanding with Abby</span>
//       </div>
//     </div>
//   </div>
// }
