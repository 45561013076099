import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from "redux-saga";

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./rootReducers";
import rootSaga from "./rootSaga";

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'thread',
        'message',
    ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureAppStore(preloadedState) {
    const sagaMiddleware = createSagaMiddleware();
    const store = configureStore({
        reducer: persistedReducer,
        middleware: [
            ...getDefaultMiddleware({
                serializableCheck: {
                    // Ignore these action types
                    ignoredActions: ["persist/PERSIST"],
                },
            }),
            sagaMiddleware,
        ],
        preloadedState,
    });
    sagaMiddleware.run(rootSaga);

    if (process.env.NODE_ENV !== "production" && module.hot) {
        module.hot.accept("./rootReducers", () =>
            store.replaceReducer(persistReducer)
        );
    }
    return store;
}
