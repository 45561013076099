import { Typewriter } from "react-simple-typewriter";

const TypingMessage = ({ length, message, only }) => {
    if (only) return <Typewriter words={[message]} typeSpeed={12} />

    return <div className="flex items-start justify-start max-w-3xl gap-2 p-0 mx-2 my-2 mr-5">
        <div className="w-8 h-8 p-1 bg-white rounded-full shadow">
            <img
                src="/imgs/logo.png"
                alt=""
                className="object-contain w-full h-full invert"
            />
        </div>
        <div className="flex-1">
            <div className="bg-transparent rounded-xl rounded-tl-none py-1.5 px-2.5 inline-block max-w-4/5">
                <p className="m-0 text-base text-left text-black break-words whitespace-pre-wrap">
                    <Typewriter key={length} words={[message]} typeSpeed={12} />
                </p>
            </div>
        </div>
        {/* <p className="text-gray-500 text-sm pl-2.5">{role}</p> */}
    </div>;
};

export default TypingMessage;
