import { all, call, put, takeLatest } from 'redux-saga/effects'

import { getThreads, getThreadsFailure, getThreadsSuccess } from './threadSlice';

import { getThread } from '../../services/threadAPI';

function* getThreadsAPI(action) {
  try {
    const response = yield call(() => getThread(action.payload));
    yield put(getThreadsSuccess(response.data));
  } catch (e) {
    yield put(getThreadsFailure());
  }
}

export default function* rootSaga() {
  yield all([takeLatest(getThreads, getThreadsAPI)]);
}
