import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  page: 1,
  total: 0,
  pageSize: 10,
}

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    updatePageState(state, action) {
      state.page = action.payload.page;
      state.total = action.payload.total;
      state.pageSize = action.payload.pageSize;
    },
  },
});

export const { updatePageState } = conversationSlice.actions;
export default conversationSlice.reducer;
