import React from 'react';
import { useSelector } from 'react-redux';
import {
  Outlet,
  Navigate,
  useLocation,
} from 'react-router-dom';

import { getStorage, setStorage } from '../../helpers';

function PrivateRoute() {
  const location = useLocation();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const logout = getStorage('logout');
  setStorage('logout');
  return isAuthenticated ? <Outlet /> : <Navigate to='/login' state={{ from: logout ? null : location }} />;
}

export default PrivateRoute;