import { createSlice } from "@reduxjs/toolkit";
import { getStorage, setStorage } from "../../helpers";

const initialState = {
    isDarkMode: false,
    siderCollapsed: false,
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setDarkMode(state, action) {
            state.isDarkMode = !state.isDarkMode;
        },
        setSiderCollapsed(state, action) {
            state.siderCollapsed = action.payload;
        },
        setTo(state, action) {
            state.to = action.payload;
        },
    },
});

export const {
    setDarkMode,
    setSiderCollapsed,
    setTo,
} = appSlice.actions;

export default appSlice.reducer;
