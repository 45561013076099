import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Progress, Typography, message, notification } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const { Title } = Typography;

const PurposeModal = ({
  open,
  onClose,
}) => {
  
  const [goal, setGoal] = useState("");

  const siderCollapsed = useSelector(state => state.app.siderCollapsed);

  useEffect(() => {
    if (open) {
      setGoal("");
    }
  }, [open]);

  return (
    <Modal centered classNames={{ wrapper: classNames(!siderCollapsed && "!ms-72"), content: "", mask: classNames(!siderCollapsed && "!ms-72") }} open={open} onOk={() => { }} onCancel={() => { }} footer={[]} closable={false} maskClosable={false} width={600}>
      <div className='text-center'>
        <Progress /* strokeLinecap="butt" */ percent={10} showInfo={false} size={["100%", 12]} strokeColor="#000" trailColor="#eee" />
        <div className={classNames("flex mt-4 items-stretch gap-4 flex-col")}>
          <div className={classNames("flex-1 flex flex-col justify-around items-center gap-4")}>
            <Title level={3} className='!font-extrabold'>Give Abby a Goal for<br />This Conversation</Title>

            <div className="w-full">
              <Input.TextArea placeholder='Let Abby know your goal for this chat so she can focus on what matters most & guide the conversation in the right direction.' rows={4} className='w-full focus:!shadow-none' value={goal} onChange={(e) => setGoal(e.target.value)} />
            </div>

            <Button className={classNames('px-12 font-bold')} size='large' type='primary' onClick={() => {
              if (!goal.trim()) { 
                return notification.warning({
                  message: 'Please enter a goal for this chat.',
                });
              }
              onClose(goal);
            }}>Start Conversation</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PurposeModal;