import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  threads: [],
  selectedThread: null,
  showExpiredModal: false,
  openPayModal: false,
}

const threadSlice = createSlice({
  name: 'thread',
  initialState,
  reducers: {
    getThreads(state) {
      state.loading = true;
    },
    getThreadsSuccess(state, action) {
      state.loading = false;
      state.threads = action.payload.threads;
    },
    getThreadsFailure(state) {
      state.loading = false;
      state.threads = [];
    },
    addThread(state, action) {
      state.threads = [action.payload, ...state.threads];
      state.selectedThread = action.payload._id;
    },
    setSelectedThread(state, action) {
      state.selectedThread = action.payload;
    },
    setShowExpiredModal(state, action) {
      state.showExpiredModal = action.payload;
    },
    initThread(state, action) {
      state.threads = [];
      state.loading = false;
      state.selectedThread = null;
      state.showExpiredModal = false;
    },
    setOpenPayModal(state, action) {
      state.openPayModal = !state.openPayModal;
    },
  },
});

export const { getThreads, getThreadsSuccess, getThreadsFailure, setSelectedThread, setShowExpiredModal, initThread, setOpenPayModal, addThread } = threadSlice.actions;
export default threadSlice.reducer;
